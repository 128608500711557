import React, { useState } from "react";
import { Modal, Box, Button, Typography, Backdrop } from "@mui/material";
import * as XLSX from "xlsx";
import { primaryColor, secondaryColor } from "../theme/setThemeColor";
import { excel2Json } from "../utils/exportToExcel";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import Spinner from "../commons/Spinner";

// Modal styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExcelBulkUpload = ({ variant, py, mt, username }) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Function to handle file upload and API call
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      excel2Json(file, setLoading)
        .then((data) => {
          setExcelData(data);
          // Call API with the Excel JSON data
          postJsonData(
            ApiEndpoints.ADMIN_UPLOAD_OLD_BENE, // Replace with your actual API endpoint
            { data }, // Sending the Excel JSON as payload
            setLoading, // Progress indicator
            (response) => {
              okSuccessToast(response.data.message);
              handleClose();
            },
            (error) => {
              apiErrorToast(error);
            }
          );
        })
        .catch((error) => {
          console.error("Error reading Excel file:", error);
        });
    }
  };

  return (
    <div>
      <Button
        variant={variant || "contained"}
        sx={{
          fontSize: "10px",
          marginLeft: variant ? "" : "5px",
          background: variant ? "" : secondaryColor(),
          py: py && 0.3,
          mt: mt && 1,
          color: "#fff",
        }}
        onClick={handleOpen}
        className="otp-hover-purple"
      >
        Upload Excel
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="sm_modal">
            <Spinner loading={loading} />
            <ModalHeader
              title="Upload Express Beneficiary"
              handleClose={handleClose}
            />
            <Box>
              <Box sx={{ my: 5, display: "flex", justifyContent: "center" }}>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
              </Box>
            </Box>
            <ModalFooter
              request={loading}
              btn="Upload Excel"
              onClick={handleFileUpload}
            />
          </Box>
        </Modal>
      </Modal>
    </div>
  );
};

export default ExcelBulkUpload;
